<template>
  <div class="home">
    <div class="block">
      <b-loading :is-full-page="isFullPage" v-model="isLoading"></b-loading>
      <button class="button field is-info is-light z-10" @click="openModal('new-service')">
        <b-icon icon="plus"></b-icon>
        <span>Novo serviço</span>
      </button>

      <button class="button field is-info is-light z-10" style="margin-left: 10px" @click="openLogisticPage()">
        <span>Logística</span>
      </button>

      <button class="button field is-info is-light z-10" style="margin-left: 10px" @click="openModal('shared-service')">
        <span>Solicitações de compartilhamento</span>
      </button>
      <button class="button field is-info is-light z-10" style="margin-left: 10px" @click="
        resetModel()
      newServiceModalActive = false
      sharedServiceModalActive = false
      isStatusChangeModalActive = true
      isCloneServiceModalActive = false
      isDriverCardModalActive = false
      isPassengerModalActive = false
        ">
        <span>Alterar status</span>
      </button>
      <button class="button field is-info is-light z-10" style="margin-left: 10px" @click="
        resetModel()
      newServiceModalActive = false
      isStatusChangeModalActive = false
      isCloneServiceModalActive = true
      isDriverCardModalActive = false
      isPassengerModalActive = false
        ">
        <span>Clonar Serviço</span>
      </button>
      <button class="button field is-info is-light z-10" style="margin-left: 10px" @click="
        serviceList.showAllServices = !serviceList.showAllServices
      listServices()
        ">
        <span>{{
          serviceList.showAllServices
          ? 'Esconder serviços'
          : 'Mostrar todos serviços'
        }}</span>
      </button>
    </div>
    <section>
      <b-field>
        <b-input placeholder="Pesquisar serviço..." type="search" icon="magnify" icon-clickable v-model="filterServices"
          @input="searchServices">
        </b-input>
      </b-field>
      <div class="block">
        <b-field>
          <b-datepicker style="width: 300px" v-model="serviceList.selectedDate" rounded
            placeholder="Clique para selecionar..." icon="calendar-today">
            <b-button label="Clear" type="is-danger" icon-left="close" outlined
              @click="serviceList.selectedDate = null" />
          </b-datepicker>

          <b-input class="ml-2" placeholder="Informe um horário" :value="serviceList.selectedTime"
            v-model="serviceList.selectedTime" v-cleave="mask.time">
          </b-input>

          <button class="button field is-info is-dark z-10" style="margin-left: 10px" @click="listServices()">
            <span>Filtrar</span>
          </button>
        </b-field>
      </div>
      <div class="block">
        <b-radio @input="filterByStatus" type="is-white" v-model="radio" name="name" native-value="all">
          Todos
        </b-radio>
        <b-radio @input="filterByStatus" type="is-primary" v-model="radio" name="name" native-value="Não confirmado">
          Não confirmado
        </b-radio>
        <b-radio @input="filterByStatus" type="is-info is-light" v-model="radio" name="name" native-value="Confirmado">
          Confirmado
        </b-radio>
        <b-radio @input="filterByStatus" type="is-warning" v-model="radio" name="name" native-value="Pendente">
          Pendente
        </b-radio>
        <b-radio @input="filterByStatus" type="is-dark" v-model="radio" name="name" native-value="Aceito">
          Aceito
        </b-radio>
        <b-radio @input="filterByStatus" type="is-danger" v-model="radio" name="name" native-value="Cancelado">
          Cancelado
        </b-radio>
        <b-radio @input="filterByStatus" type="is-warning is-light" v-model="radio" name="name" native-value="Cancelado">
          Em andamento
        </b-radio>
        <b-radio @input="filterByStatus" type="is-success" v-model="radio" name="name" native-value="Finalizado">
          Finalizado
        </b-radio>
      </div>
    </section>
    <br />

    <b-table :data="serviceList.data" :loading="serviceList.loading" ref="table" paginated per-page="10"
      backend-pagination :total="serviceList.total" detailed detail-key="id" :striped="true" :debounce-search="1000"
      :show-detail-icon="true" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
      aria-current-label="Current page" @details-open="(row) => $buefy.toast.open(`Visualizando os detalhes`)"
      @page-change="onPageChange">
      <b-table-column field="id" label="Nº OS" width="40" numeric v-slot="props">#{{ props.row.id }}</b-table-column>

      <b-table-column field="service_date" label="Data/Hora do serviço" sortable v-slot="props">
        <template v-if="showDetailIcon"><b-taglist>
            <b-tag :type="getTypeByStatus(props.row.status)" size="is-medium">{{
              props.row.service_date
              ? moment(props.row.service_date).format('DD/MM') +
              ' ' +
              props.row.service_time
              : 'N/A'
            }}
            </b-tag>
          </b-taglist></template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)"><b-taglist>
              <b-tag :type="getTypeByStatus(props.row.status)" size="is-medium">{{
                props.row.service_date
                ? $moment(props.row.service_date).format('DD/MM') +
                ' ' +
                props.row.service_time
                : 'N/A'
              }}
              </b-tag>
            </b-taglist></a>
        </template>
      </b-table-column>

      <b-table-column field="itinerary" label="Itinerário" sortable v-slot="props">{{
        props.row.itinerary ? props.row.itinerary.toUpperCase() : 'N/A'
      }}</b-table-column>

      <b-table-column field="requester" label="Solicitante" sortable v-slot="props">{{
        props.row.requester ? props.row.requester.name.toUpperCase() : 'N/A'
      }}</b-table-column>

      <b-table-column field="driver" label="Motorista" sortable v-slot="props"><b-taglist>
          <b-tag :type="props.row.driver
            ? props.row.driver_confirmation_date
              ? 'is-info is-light'
              : 'is-danger'
            : 'is-dark'
            " size="is-medium">{{
    props.row.driver
    ? props.row.driver.nickname
      ? props.row.driver.nickname.toUpperCase()
      : props.row.driver.name.toUpperCase() +
      ' ' +
      props.row.driver.last_name.toUpperCase()
    : 'N/A'
  }}
          </b-tag>
        </b-taglist></b-table-column>

      <b-table-column field="status" label="Status" sortable v-slot="props">{{
        props.row.status.toString().toUpperCase()
      }}</b-table-column>

      <b-table-column v-slot="props" label="Observações">
        <button class="button is-small is-light" @click.prevent="
          onViewObservations(props.row)
        onEdit(props.row)
        isPassengerModalActive = false
        isDriverCardModalActive = false
        newServiceModalActive = false
        isOccurrenceCardModalActive = false
        isObservationCardModalActive = true
          ">
          {{
            props.row.service_observations
            ? props.row.service_observations.length
            : 0
          }}
          Observações
        </button>
      </b-table-column>

      <b-table-column v-slot="props" label="Ocorrências">
        <button class="button is-small is-warning" @click.prevent="
          onViewOccurrences(props.row)
        onEdit(props.row)
        listDrivers()
        isPassengerModalActive = false
        isDriverCardModalActive = false
        newServiceModalActive = false
        isOccurrenceCardModalActive = true
          ">
          {{ props.row.service_occurrences.length }} Ocorrência(s)
        </button>
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button class="button is-small is-warning" @click.prevent="
          openModal('select-passenger')
        onEdit(props.row)
        onViewPassengers(props.row)
          ">
          <b-icon icon="account-details" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-info" @click.prevent="
          openModal('change-driver')
        model.id = props.row.id
          ">
          <b-icon icon="account-convert" size="is-small"></b-icon>
        </button>

        <button class="button is-small is-light" @click.prevent="
          openModal('new-service')
        onEdit(props.row)
          ">
          <b-icon icon="pen" size="is-small"></b-icon>
        </button>
        <button class="button is-small is-danger" @click.prevent="onCancel(props.row.id)">
          <b-icon icon="block-helper" size="is-small"></b-icon>
        </button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p><strong>Nº OS:</strong> &nbsp;#{{ props.row.id }}</p>
              <b-button style="margin-right: 10px" @click="confirmService(props.row.id)"
                v-if="props.row.status === 'Não confirmado'" type="is-info">Confirmar serviço</b-button>
              <b-button style="margin-right: 10px" @click="sendToDriver(props.row.id)"
                v-if="props.row.status === 'Confirmado'" type="is-info">Enviar para o motorista</b-button>
              <b-button style="margin-right: 10px" v-if="props.row.status !== 'Finalizado' &&
                props.row.status !== 'Cancelado'
                " @click="onCancel(props.row.id)" type="is-danger">Cancelar serviço</b-button>
              <b-button v-if="props.row.status !== 'Finalizado' &&
                props.row.status !== 'Cancelado'
                " @click="onFinish(props.row.id)" type="is-success">Finalizar serviço</b-button>
              <b-taglist style="margin-top: 10px">
                <b-tag style="font-weight: bold; font-size: 14px" :type="getTypeByStatus(props.row.status)"
                  size="is-medium">{{ props.row.status.toUpperCase() }}</b-tag>
              </b-taglist>
              <p>
                <strong>Data/Hora do serviço:</strong> &nbsp;{{
                  $moment(props.row.service_date).format('DD/MM')
                }}
                {{ props.row.service_time }}

                <strong>Itinerário:</strong> &nbsp;
                {{ props.row.itinerary ? props.row.itinerary : 'N/A' }} &nbsp;

                <strong>Solicitante:</strong> &nbsp;{{
                  props.row.requester
                  ? props.row.requester.name.toUpperCase()
                  : 'N/A'
                }}

                <strong>Veículo:</strong> &nbsp;{{
                  props.row.vehicle
                  ? props.row.vehicle.vehicle_model.model.toUpperCase()
                  : 'N/A'
                }}
                &nbsp;
                {{
                  props.row.vehicle
                  ? '(' + props.row.vehicle.plate.toUpperCase() + ')'
                  : ''
                }}

                <strong>Motorista:</strong> &nbsp;{{
                  props.row.driver
                  ? props.row.driver.name.toUpperCase() +
                  ' ' +
                  props.row.driver.last_name.toUpperCase()
                  : 'N/A'
                }}

                <strong>Tipo de viagem:</strong> &nbsp;
                {{ props.row.type === 'Ida' ? 'Entrada' : 'Saída' }}

                <br />
                <strong>Passageiros:</strong>
                <br />
                <span v-for="(passenger, indexPassenger) in props.row.passengers" :key="indexPassenger">
                  <strong>• {{ passenger.name }}</strong>
                  - Matrícula:&nbsp; {{ passenger.registration }} -
                  Contato:&nbsp;
                  <a @click="copyToClipboard(passenger.phone_number)">{{
                    passenger.phone_number ? passenger.phone_number : 'N/A'
                  }}</a>
                  - Endereço de embarque:&nbsp;
                  {{
                    passenger.pivot.origin_address
                    ? passenger.pivot.origin_address
                    : props.row.address
                  }}
                  - Endereço de destino:&nbsp;
                  {{
                    passenger.pivot.destination_address
                    ? passenger.destination_address
                    : props.row.address
                  }}
                  - Horário de embarque:&nbsp;
                  {{ passenger.pivot.boarding_time }}
                  - Valor adicional:&nbsp; R$
                  {{ parseFloat(passenger.pivot.additional_value).toFixed(2)
                  }}<br />
                </span>
              </p>
              <p>
                <strong>Informação adicional:</strong> &nbsp;{{
                  props.row.additional_information
                  ? props.row.additional_information
                  : 'N/A'
                }}
              </p>
              <p v-if="props.row.boarding_line">
                <strong>Pontos de embarque (Linha):</strong><br />
                <span v-for="(boardingPoint, indexPoint) in props.row.boarding_line
                      .boarding_points" :key="indexPoint">
                  - {{ boardingPoint.boarding_place }} ({{
                    boardingPoint.boarding_time
                  }})
                </span>
              </p>
              <p>
                <strong>Valor:</strong> &nbsp;R$
                {{ parseFloat(props.row.value).toFixed(2) }}
              </p>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <b-modal v-model="isDriverCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field label="Motorista">
              <b-select expanded placeholder="Selecione um motorista" required v-model="model.driver_id">
                <option v-for="(item, index) in driverList.data" :key="index" :value="item.id">
                  {{
                    item.nickname
                    ? item.nickname.toUpperCase()
                    : item.name + ' ' + item.last_name
                  }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" @click="changeDriver" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal class="fullscreen-modal" v-model="isStatusChangeModalActive" width="100%" scroll="keep">
      <div class="card" style="height: 3500px !important">
        <div class="card-content">
          <div class="content">
            <b-field label="Serviços">
              <multiselect v-model="selectedServices" :options="serviceList.data" :multiple="true"
                :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                placeholder="Selecione os serviços" label="nameTemp" track-by="id" :preselect-first="true"></multiselect>
            </b-field>
            <b-button type="button field is-info" @click="changeStatusServices('Finalizado')" class="mt-10">Finalizar
              serviços</b-button>
            <b-button type="button field is-info" style="margin-left: 10px" @click="changeStatusServices('Confirmado')"
              class="mt-10">Confirmar serviços</b-button>
            <br />
            <b-button type="button field is-info" @click="changeStatusServices('Pendente')" class="mt-10">Enviar para os
              motoristas</b-button>
            <b-button type="button field is-info" style="margin-left: 10px" @click="changeStatusServices('Cancelado')"
              class="mt-10">Cancelar serviços</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isCloneServiceModalActive" width="100%" scroll="keep">
      <div class="card" style="height: 900px">
        <div class="card-content">
          <div class="content">
            <b-field label="Serviços">
              <multiselect v-model="selectedServices" :options="allFilterServicesTemp" :multiple="true"
                :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                placeholder="Selecione os serviços" label="nameTemp" track-by="id" :preselect-first="true"></multiselect>
            </b-field>
            <b-button type="button field is-info" @click="cloneServices()" class="mt-10">Clonar serviço(s)</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isOccurrenceCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <p v-if="viewOccurrences">
              <strong>Ocorrências:</strong><br />
              <span v-for="(occurrence, indexOccurrence) in viewOccurrences" :key="indexOccurrence">
                <br />
                <strong>• Ocorrência {{ indexOccurrence + 1 }}</strong><br />
                - Mensagem: &nbsp; {{ occurrence.message }}<br />
                - Motorista: &nbsp;
                {{
                  occurrence.driver
                  ? occurrence.driver.name + ' ' + occurrence.driver.last_name
                  : 'N/A'
                }}<br />
                <hr />
              </span>
            </p>
            <b-field label="Ocorrência">
              <b-input v-model="modelOccurrence.message" maxlength="200" type="textarea"></b-input>
            </b-field>
            <b-field label="Motorista">
              <b-select expanded placeholder="Selecione um motorista" required v-model="modelOccurrence.driver_id">
                <option v-for="(item, index) in driverList.data" :key="index" :value="item.id">
                  {{
                    item.nickname
                    ? item.nickname.toUpperCase()
                    : item.name + ' ' + item.last_name
                  }}
                </option>
              </b-select>
            </b-field>
            <b-button type="button field is-info" @click="saveOccurrence" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="isObservationCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <p v-if="viewObservations">
              <strong>Observações:</strong><br />
              <span v-for="(observation, indexObservation) in viewObservations" :key="indexObservation">
                <br />
                <strong>• Observação {{ indexObservation + 1 }}</strong><br />
                - Mensagem: &nbsp; {{ observation.message }}<br />
                <hr />
              </span>
            </p>
            <b-field label="Observação">
              <b-input v-model="modelObservation.message" maxlength="200" type="textarea"></b-input>
            </b-field>
            <b-button type="button field is-info" @click="saveObservation" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal can-cancel="['escape', 'x']" v-model="isPassengerModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-taglist v-if="this.model.itinerary" attached>
              <b-tag type="is-dark">Itinerário atual</b-tag>
              <b-tag type="is-success">{{ this.model.itinerary }}</b-tag>
            </b-taglist>
            <b-taglist v-if="this.model.vehicle_id" attached>
              <b-tag type="is-dark">Passageiros</b-tag>
            </b-taglist>
            <p v-if="serviceModel.passengers">
              <strong>Passageiros:</strong><br />
              <span v-for="(item, indexPassenger) in findListPassengers()" :key="indexPassenger">
                <div v-if="item.passenger">
                  <br />
                  <strong>• {{ item.passenger.name.toUpperCase() }}</strong><br />
                  - Matrícula:&nbsp; {{ item.passenger.registration }}<br />
                  - Centro de custo:&nbsp;
                  {{
                    item.passenger.costCenter
                    ? item.passenger.costCenter.name
                    : 'N/A'
                  }}<br />
                  - Endereço de embarque:&nbsp;
                  <a @click="copyToClipboard(item.origin_address)">{{
                    item.origin_address || 'N/A'
                  }}</a><br />
                  - Endereço de destino:&nbsp;
                  <a @click="copyToClipboard(item.destination_address)">{{
                    item.destination_address || 'N/A'
                  }}</a><br />
                  - Horário de embarque:&nbsp; {{ item.boarding_time || 'N/A'
                  }}<br />
                </div>
                <!-- - Cliente:&nbsp;
                  {{
                    getCustomerById(
                      passenger.pivot.customer_id
                    ).name.toUpperCase()
                  }}<br /> -->
                - Valor adicional:&nbsp;
                <!-- {{
                  item.additional_value
                    ? parseFloat(item.additional_value).toFixed(2)
                    : 0.0
                }} -->
                {{ item.additional_value || 0.0 }}
                <br />
                - Pedágio:&nbsp; R$
                <!-- {{
                  item.toll_value
                    ? parseFloat(item.toll_value).toFixed(2)
                    : 0.0
                }} -->
                {{ item.toll_value || 0.0 }}
                <br />
                - Valor da viagem:&nbsp; R$
                <!-- {{
                  item.toll_value
                    ? parseFloat(item.toll_value).toFixed(2)
                    : 0.0
                }} -->
                {{ item.totalValueTmp || 0.0 }}
              </span>
            </p>
            <hr />
            <b-field v-if="model.type && model.requester_id" label="Passageiros">
              <button class="button field is-info is-light" @click="addPassenger">
                <b-icon icon="plus"></b-icon>
                <span>Adicionar passageiro</span>
              </button>
            </b-field>
            <b-field v-for="(item, index) of serviceModel.passengers" :key="index">
              <b-collapse class="card" animation="slide" :open="isOpen == index" @open="isOpen = index">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">
                    {{
                      item.passenger
                      ? item.passenger.name
                      : 'Nenhum passageiro selecionado'
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Passageiro">
                      <b-autocomplete :data="passengerList.data" placeholder="Informe o nome do passageiro" field="name"
                        :loading="passengerList.loading" :value="serviceModel.passengers[index].passenger
                          ? serviceModel.passengers[index].passenger.name
                          : ''
                          " @typing="findPassenger" @select="(option) => selectPassenger(option, index)">
                        <template slot-scope="props">
                          <div class="media">
                            <div class="media-content">
                              {{ props.option.name }}
                              <small v-if="props.option.customer">
                                <br />
                                <span v-if="props.option.customer.cost_center">
                                  <b>CC:</b>
                                  {{ props.option.customer.cost_center.code }}
                                </span>
                                <b>Cliente:</b>
                                {{ props.option.customer.name }}
                                <b>Matrícula:</b>
                                {{ props.option.registration }}
                              </small>
                            </div>
                          </div>
                        </template>
                      </b-autocomplete>
                    </b-field>
                    <b-field v-if="item.id && !verifyPassenger(item)">
                      <p>Este passageiro possui pendências em seu cadastro</p>
                    </b-field>
                    <b-field label="Lançamentos pendentes (feito pelo solicitante)"
                      v-if="allFilterPendingReleases.length > 0">
                      <b-select expanded @input="selectPendingRelease(index)" placeholder="Selecione um lançamento"
                        required v-model="serviceModel.passengers[index].pending_release_id
                          ">
                        <option v-for="(item, index) in allFilterPendingReleases" :key="index" :value="item.id">
                          {{ $moment(item.boarding_date).format('DD/MM') }} -
                          {{ item.boarding_time }} / Endereço:
                          {{ item.address.toUpperCase() }}
                        </option>
                      </b-select>
                    </b-field>
                    <p>Valor calculado: R$ {{ item.value }}</p>
                    <b-field label="Valor manual">
                      <b-input v-money="moneyFormat" v-model="serviceModel.passengers[index].tempValue"
                        :value="serviceModel.passengers[index].tempValue" @input="onChangeManualValue">
                      </b-input>
                    </b-field>
                    <b-field>
                      <p>
                        OBS: Se o valor digitado for maior que zero, ele irá
                        substituir o valor calculado pelo sistema!
                      </p>
                    </b-field>
                    <b-field label="Horário de embarque">
                      <b-input placeholder="Selecione um horário" v-model="serviceModel.passengers[index].boarding_time"
                        :value="serviceModel.passengers[index].boarding_time" v-cleave="mask.time">
                      </b-input>
                    </b-field>
                    <b-field label="Hora parada">
                      <b-input type="number" :value="serviceModel.passengers[index].stop_time"
                        v-model="serviceModel.passengers[index].stop_time" @input="onChangeStopTime">
                      </b-input>
                    </b-field>
                    <b-field label="Centro de custo">
                      <b-input required v-model="item.cost_center"></b-input>
                    </b-field>
                    <b-field label="Valor adicional *">
                      <b-input required v-money="moneyFormat" v-model="serviceModel.passengers[index].additional_value
                        "></b-input>
                    </b-field>
                    <b-field label="Endereço de embarque">
                      <b-input v-model="serviceModel.passengers[index].origin_address"></b-input>
                    </b-field>
                    <b-field label="Endereço de destino">
                      <b-input v-model="serviceModel.passengers[index].destination_address
                        "></b-input>
                    </b-field>
                    <b-field
                      v-if="serviceModel.passengers[index].passenger && serviceModel.passengers[index].passenger.locations"
                      label="Localidade">
                      <b-select expanded placeholder="Selecione uma localidade" required
                        v-model="serviceModel.passengers[index].location_id"
                        @input="selectPassenger(serviceModel.passengers[index].passenger, index)">
                        <option v-for="(item, index) in serviceModel.passengers[index].passenger.locations" :key="index"
                          :value="item.id">
                          {{ item.name.toUpperCase() }}
                        </option>
                      </b-select>
                    </b-field>
                  </div>
                </div>
                <footer class="card-footer">
                  <a @click="removePassenger(index)" class="card-footer-item">Remover</a>
                </footer>
              </b-collapse>
            </b-field>
            <b-button type="button field is-info" @click="savePassengers" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="sharedServiceModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field v-if="!allSharedServicePassengers.length">
              Nenhuma solicitação encontrada
            </b-field>
            <b-field v-for="(item, index) of allSharedServicePassengers" :key="index">
              <b-collapse class="card" animation="slide" :open="isOpen == index" @open="isOpen = index">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">
                    {{ item.passenger.name }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <p>
                      <span>
                        <br />
                        <strong>• {{ item.passenger.name.toUpperCase() }}</strong><br />
                        - Endereço:&nbsp;
                        <a @click="copyToClipboard(item.address)">{{
                          item.address
                        }}</a><br />
                        - Horário de embarque:&nbsp; {{ item.boarding_time
                        }}<br />
                        - Serviço:&nbsp; #{{ item.service_id }}<br />
                      </span>
                    </p>
                  </div>
                </div>
                <footer class="card-footer">
                  <a @click="removeSharedPassenger(item.id)" class="card-footer-item">Remover</a>
                </footer>
              </b-collapse>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="newServiceModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-content">
          <div class="content">
            <b-field>
              <b-checkbox v-if="!model.id" v-model="model.recurrence">
                Recorrência
              </b-checkbox>
            </b-field>
            <b-field v-if="model.recurrence" label="Datas/Horários dos serviços">
              <button class="button field is-info is-light" @click="addServiceDate">
                <b-icon icon="plus"></b-icon>
                <span>Adicionar data</span>
              </button>
            </b-field>
            <b-field v-if="model.recurrence">
              <b-collapse class="card" animation="slide" v-for="(serviceDate, index) of serviceDates" :key="index"
                :open="isOpen == index" @open="isOpen = index">
                <div slot="trigger" slot-scope="props" class="card-header" role="button">
                  <p class="card-header-title">
                    {{
                      serviceDate.service_date && serviceDate.service_time
                      ? $moment(serviceDate.service_date).format(
                        'DD/MM/YYYY'
                      ) +
                      ' ' +
                      serviceDate.service_time
                      : 'Nenhuma data selecionada'
                    }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field label="Data do serviço *">
                      <b-datepicker ref="datepicker" inline v-model="serviceDates[index].service_date"
                        placeholder="Selecione uma data"></b-datepicker>
                    </b-field>
                    <b-field label="Horário do serviço">
                      <b-input placeholder="Selecione um horário" :value="serviceDates[index].service_time"
                        v-model="serviceDates[index].service_time" v-cleave="mask.time">
                      </b-input>
                    </b-field>
                  </div>
                </div>
                <footer class="card-footer">
                  <a @click="removeServiceDate(index)" class="card-footer-item">Remover</a>
                </footer>
              </b-collapse>
            </b-field>
            <b-field v-if="!model.recurrence" label="Data do serviço *">
              <b-datepicker ref="datepicker" expanded v-model="model.service_date" locale="pt-BR"
                placeholder="Selecione uma data"></b-datepicker>
              <b-button @click="$refs.datepicker.toggle()" icon-left="calendar-today" type="is-primary" />
            </b-field>
            <b-field v-if="!model.recurrence" label="Horário do serviço">
              <b-input placeholder="Selecione um horário" :value="model.service_time" v-model="model.service_time"
                v-cleave="mask.time">
              </b-input>
            </b-field>
            <b-field>
              <b-checkbox v-model="model.holiday">Feriado</b-checkbox>
            </b-field>
            <b-field label="Tipo">
              <b-select @input="normalizePassengers" expanded placeholder="Selecione um tipo" required
                v-model="model.type">
                <option v-for="(item, index) in ['Ida', 'Volta']" :key="index" :value="item">
                  {{ item === 'Ida' ? 'Entrada' : 'Saída' }}
                </option>
              </b-select>
            </b-field>
            <!-- <b-field v-if="!model.recurrence" label="Data de confirmação *">
              <b-datepicker
                ref="datepicker"
                expanded
                v-model="model.confirmation_date"
                placeholder="Selecione uma data"
              ></b-datepicker>
              <b-button
                @click="$refs.datepicker.toggle()"
                icon-left="calendar-today"
                type="is-primary"
              />
            </b-field> -->
            <!-- <b-field label="Clientes">
              <multiselect
                v-model="model.customers"
                :options="allFilterCustomers"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione os clientes"
                label="name"
                track-by="id"
                :preselect-first="false"
                @input="selectCustomer"
              ></multiselect>
            </b-field> -->
            <!-- <b-field label="Cliente">
              <b-select
                expanded
                placeholder="Selecione um cliente"
                required
                v-model="model.customer_id"
              >
                <option
                  v-for="(item, index) in allFilterCustomers"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </b-select>
            </b-field> -->
            <!-- <b-field v-if="model.vehicle_id && getVehicleById(model.vehicle_id).utility
              " label="Linha">
              <b-select expanded placeholder="Selecione uma linha" required v-model="model.boarding_line_id">
                <option v-for="(item, index) in allBoardingLines" :key="index" :value="item.id">
                  {{ item.identification }}
                </option>
              </b-select>
            </b-field> -->
            <b-field label="Solicitante">
              <b-select expanded placeholder="Selecione um solicitante" required v-model="model.requester_id">
                <option v-for="(item, index) in requesterList.data" :key="index" :value="item.id">
                  {{ item.name.toUpperCase() }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Motorista">
              <b-select expanded placeholder="Selecione um motorista" required v-model="model.driver_id">
                <option v-for="(item, index) in driverList.data" :key="index" :value="item.id">
                  {{
                    item.nickname
                    ? item.nickname.toUpperCase()
                    : item.name + ' ' + item.last_name
                  }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Valor manual *">
              <b-input required v-money="moneyFormat" v-model="model.manual_value"></b-input>
            </b-field>
            <!-- <b-field label="Veículo">
              <b-select
                expanded
                placeholder="Selecione um veículo"
                required
                v-model="model.vehicle_id"
              >
                <option
                  v-for="(item, index) in allVehicles"
                  :key="index"
                  :value="item.id"
                >
                  {{ item.plate.toUpperCase() }} -
                  {{ item.vehicle_model.model.toUpperCase() }} -
                  {{ item.vehicle_model.brand.toUpperCase() }}
                </option>
              </b-select>
            </b-field> -->
            <b-field :label="!model.type || model.type === 'Ida'
              ? 'Endereço de destino'
              : 'Endereço de embarque'
              ">
              <b-input v-model="model.address"></b-input>
            </b-field>
            <b-field label="Taxas">
              <b-select multiple expanded native-size="3" v-model="model.taxes">
                <option v-for="(item, index) in allTaxes" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Pedágio">
              <b-select placeholder="Selecione um pedágio" required v-model="model.toll_id">
                <option v-for="(item, index) in allTolls" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </b-select>
            </b-field>
            <!-- <b-field label="Valor *">
              <b-input
                required
                v-money="moneyFormat"
                v-model="model.value"
              ></b-input>
            </b-field> -->
            <b-field label="Informação adicional">
              <b-input required v-model="model.additional_information"></b-input>
            </b-field>
            <b-field>
              <b-checkbox v-model="model.shared">Viagem compartilhada</b-checkbox>
            </b-field>
            <b-button type="button field is-info" @click="save" class="mt-10">Salvar</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import { EventBus } from '../event-bus.js'

import service from '../features/services/store/service'
import requester from '../features/requesters/store/service'
import driver from '../features/drivers/store/service'
import passenger from '../features/passengers/store/service'
import location from '../features/locations/store/service'
import serviceSharedServicePassenger from '../features/shared-service-passengers/store/service'
import serviceOccurrence from '../features/service-occurrences/store/service'
import serviceObservation from '../features/service-observations/store/service'

import debounce from 'lodash/debounce'

import Service from '../models/service'

import ServiceStatus from '../common/data/service-status.data'

import moment from 'moment'

// import StatesCities from '../assets/data/estados-cidades.json'

import Cleave from 'cleave.js'

/**
 * We add a new instance of Cleave when the element
 * is bound and destroy it when it's unbound.
 */
const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  },
}

export default {
  name: 'Services',
  directives: { cleave },
  data() {
    return {
      model: Service.model,
      modelOccurrence: Service.modelOccurrence,
      modelObservation: Service.modelObservation,
      newServiceModalActive: false,
      isDriverCardModalActive: false,
      isPassengerModalActive: false,
      isStatusChangeModalActive: false,
      isCloneServiceModalActive: false,
      sharedServiceModalActive: false,
      isOccurrenceCardModalActive: false,
      isObservationCardModalActive: false,

      //New structure
      services: [],
      serviceList: {
        total: 0,
        currentPage: 1,
        data: [],
        showAllServices: false,
        selectedDate: null,
        selectedTime: null,
        loading: false,
      },

      requesterList: {
        data: [],
        loading: false,
      },

      driverList: {
        data: [],
        loading: false,
      },

      locationList: {
        data: [],
        loading: false,
      },

      passengerList: {
        data: [],
        loading: false,
      },

      serviceModel: {
        passengers: [],
      },

      filterServices: '',

      //Old structure

      isLoading: false,
      isFullPage: true,
      passengers: [],
      serviceDates: [],
      isOpen: null,
      isOpenList: null,
      activeTab: 0,
      serviceFilter: [],
      radio: null,
      showDetailIcon: null,
      allFilterDrivers: [],
      allFilterPassengers: [],
      allFilterPassengersTemp: [],
      allServicesTemp: [],
      allFilterServicesTemp: [],
      viewPassengers: [],
      viewOccurrences: [],
      viewObservations: [],
      allFilterPendingReleases: [],
      allFilterServices: [],
      allFilterRequesters: [],
      selectedServices: [],
      searchQuery: null,
      allItinerariesFilter: [],
      allServices: [],
      showAllServices: false,
      mask: {
        time: { time: true, timePattern: ['h', 'm'] },
      },
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('vehicles', ['allVehicles']),
    ...mapState('sharedServicePassengers', ['allSharedServicePassengers']),
    ...mapState('customers', ['allCustomers']),
    ...mapState('passengers', ['allPassengers']),
    ...mapState('locations', ['allLocations']),
    ...mapState('itineraries', ['allItineraries']),
    ...mapState('drivers', ['allDrivers']),
    ...mapState('boardingLines', ['allBoardingLines']),
    ...mapState('taxes', ['allTaxes']),
    ...mapState('requesters', ['allRequesters']),
    ...mapState('pendingReleases', ['allPendingReleases']),
    ...mapState('tolls', ['allTolls']),

    searchPassengerTmp() {
      console.log('searchPassenger')
      return ''
    },

    searchService() {
      console.log('service search')
      if (!this.searchQuery) {
        console.log('all services 2', this.allServices)
        const tempServices = !this.allServices
          ? []
          : this.allServices.filter((x) => x.status !== 'Cancelado')
        return tempServices
          .map((x) => {
            const dateTemp = new Date(x.service_date)
            if (x.service_time) {
              dateTemp.setHours(x.service_time.split(':')[0])
              dateTemp.setMinutes(x.service_time.split(':')[1])
            }
            return {
              ...x,
              tempDate: dateTemp,
            }
          })
          .map((x) => {
            return {
              ...x,
              passengers: x.passengers
                ? x.passengers.sort((a, b) => {
                  const aDate = new Date()
                  const bDate = new Date()

                  aDate.setHours(a.pivot.boarding_time.split(':')[0])
                  aDate.setMinutes(a.pivot.boarding_time.split(':')[1])
                  bDate.setHours(b.pivot.boarding_time.split(':')[0])
                  bDate.setMinutes(b.pivot.boarding_time.split(':')[1])

                  return aDate.getTime() - bDate.getTime()
                })
                : [],
            }
          })
          .sort((a, b) => {
            return (
              new Date(b.tempDate).getTime() - new Date(a.tempDate).getTime()
            )
          })
      } else {
        console.log('all services ', this.allServices)
        return this.allServices
          .filter((x) => x.status !== 'Cancelado')
          .filter((item) => {
            return (
              item.status
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.itinerary_destination.destination.name
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.itinerary_origin.origin.name
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.requester.name
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.driver.name
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              `${this.$moment(item.service_date).format('DD/MM')} ${item.service_time
                }`
                .toString()
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
            );
          })
          .map((x) => {
            const dateTemp = new Date(x.service_date)
            if (x.service_time) {
              dateTemp.setHours(x.service_time.split(':')[0])
              dateTemp.setMinutes(x.service_time.split(':')[1])
            }
            return {
              ...x,
              tempDate: dateTemp,
              passengers: x.passengers
                ? x.passengers.sort((a, b) => {
                  const aDate = new Date()
                  const bDate = new Date()

                  aDate.setHours(a.pivot.boarding_time.split(':')[0])
                  aDate.setMinutes(a.pivot.boarding_time.split(':')[1])
                  bDate.setHours(b.pivot.boarding_time.split(':')[0])
                  bDate.setMinutes(b.pivot.boarding_time.split(':')[1])

                  return aDate.getTime() - bDate.getTime()
                })
                : [],
            }
          })
          .sort((a, b) => {
            return (
              new Date(b.tempDate).getTime() - new Date(a.tempDate).getTime()
            )
          })
      }
    },
  },
  // sockets: {
  //   connect: function () {
  //     console.log('socket connected')
  //   },
  //   customEmit: function (data) {
  //     console.log(
  //       'this method was fired by the socket server. eg: io.emit("customEmit", data)'
  //     )
  //   },
  // },
  methods: {
    ...mapActions('vehicles', ['getAllVehicles']),
    ...mapActions('sharedServicePassengers', ['getAllSharedServicePassengers']),
    ...mapActions('customers', ['getAllCustomers']),
    ...mapActions('passengers', ['getAllPassengers']),
    ...mapActions('locations', ['getAllLocations']),
    ...mapActions('itineraries', ['getAllItineraries']),
    ...mapActions('drivers', ['getAllDrivers']),
    ...mapActions('boardingLines', ['getAllBoardingLines']),
    ...mapActions('taxes', ['getAllTaxes']),
    ...mapActions('requesters', ['getAllRequesters']),
    ...mapActions('pendingReleases', ['getAllPendingReleases']),
    ...mapActions('tolls', ['getAllTolls']),

    //Modals
    async openModal(type) {
      this.resetModel()

      switch (type) {
        case 'select-passenger':
          this.isPassengerModalActive = true
          await this.listLocations()
          console.log('service model ', this.serviceModel.passengers)
          this.calculateServiceValue()
          break
        case 'new-service':
          this.newServiceModalActive = true
          await this.listRequesters()
          await this.listDrivers()
          await this.getAllTolls()
          break
        case 'shared-service':
          this.sharedServiceModalActive = true
          break
        case 'change-driver':
          this.isDriverCardModalActive = true
          await this.listDrivers()
          break
        default:
          break
      }
    },

    onPageChange(page) {
      this.serviceList.currentPage = page
      // const queryTmp = localStorage.getItem('filter-services')
      
      // if(queryTmp.date || queryTmp.time)return;

      this.listServices()
    },

    onChangeManualValue(value) {
      console.log('onChangeManualValue ', value)

      const convertValue = value.replace('R$ ', '')
      if (parseFloat(convertValue) > 0) {
        this.calculateServiceValue()
      }
    },

    onChangeStopTime(value) {
      console.log('onChangeStopTime ', value)

      if (value && parseInt(value) > 0) {
        this.calculateServiceValue()
      }
    },

    passengerCustomOption(item) {
      return `
          CC:
                          ${item.customer && item.customer.cost_center
          ? item.customer.cost_center.code
          : 'N/A'
        }
                          - ${item.name.toUpperCase()} (${item.registration.toUpperCase()})
          `
    },
    showOrHideServices() {
      this.showAllServices = !this.showAllServices

      if (this.showAllServices) {
        this.getAllServicesTemp()
      } else {
        this.getAllCurrentServices()
      }
    },
    async init() {
      try {
        this.filterServices = localStorage.getItem('filter-services')

        this.listServices()

        // const tempServices = await service.getAllCurrentServices()

        // this.allServices = tempServices.data

        // await this.getAllVehicles()
        await this.getAllCustomers()
        // await this.getAllPassengers()
        // await this.getAllItineraries()
        // await this.getAllDrivers()
        // await this.getAllLocations()
        // await this.getAllBoardingLines()
        // await this.getAllRequesters()
        // await this.getAllTaxes()
        // await this.getAllPendingReleases()

        // await this.getAllSharedServicePassengers()

        // this.allFilterPassengersTemp = this.allPassengers.sort((a, b) => {
        //   if (a.name < b.name) {
        //     return -1
        //   }
        //   if (a.name > b.name) {
        //     return 1
        //   }
        //   return 0
        // })

        // this.allFilterCustomers = this.allCustomers.sort((a, b) => {
        //   if (a.name < b.name) {
        //     return -1
        //   }
        //   if (a.name > b.name) {
        //     return 1
        //   }
        //   return 0
        // })

        // this.allFilterDrivers = this.allDrivers
        //   .filter((x) => x.status.toLowerCase() === 'online')
        //   .map((x) => {
        //     return {
        //       ...x,
        //       nameTemp: x.nickname
        //         ? x.nickname.toUpperCase()
        //         : `${x.name.toUpperCase()} ${x.last_name.toUpperCase()}`,
        //     }
        //   })
        //   .sort((a, b) => {
        //     if (a.nameTemp < b.nameTemp) {
        //       return -1
        //     }
        //     if (a.nameTemp > b.nameTemp) {
        //       return 1
        //     }
        //     return 0
        //   })

        // this.allServicesTemp = tempServices.data
        //   .map((x) => {
        //     const dateTemp = new Date(x.service_date)
        //     if (x.service_time) {
        //       dateTemp.setHours(x.service_time.split(':')[0])
        //       dateTemp.setMinutes(x.service_time.split(':')[1])
        //     }
        //     return {
        //       ...x,
        //       nameTemp: `#${x.id} - ${
        //         x.service_date
        //           ? this.$moment(x.service_date).format('DD/MM') +
        //             ' ' +
        //             x.service_time
        //           : 'N/A'
        //       } - ${x.status} (${
        //         x.itinerary_id
        //           ? x.itinerary_origin.origin.name +
        //             ' X ' +
        //             x.itinerary_destination.destination.name
        //           : 'N/A'
        //       })`,
        //       tempDate: dateTemp,
        //     }
        //   })
        //   .sort((a, b) => {
        //     return (
        //       new Date(b.tempDate).getTime() - new Date(a.tempDate).getTime()
        //     )
        //   })

        // const tempAllServices = await service.getAllServices()

        // this.allFilterServicesTemp = tempAllServices.data

        // this.allFilterServicesTemp = this.allFilterServicesTemp.map((x) => {
        //   x.nameTemp = `#${x.id} - ${
        //     x.service_date
        //       ? this.$moment(x.service_date).format('DD/MM') +
        //         ' ' +
        //         x.service_time
        //       : 'N/A'
        //   } - ${x.status} (${
        //     x.itinerary_id
        //       ? x.itinerary_origin.origin.name +
        //         ' X ' +
        //         x.itinerary_destination.destination.name
        //       : 'N/A'
        //   })`

        //   return x
        // })

        // this.sockets.subscribe('service-change-notification', (data) => {
        //   console.log('data socketio ', data)
        //   this.playSound()
        //   this.$toastInfo(data, '#FFA000')
        // })

        // this.sockets.subscribe('driver-change-service', (data) => {
        //   console.log('data message ', data)
        //   this.playSound()
        //   let msg = ''
        //   let color = '#F44336'
        //   if (
        //     this.allFilterDrivers.find(
        //       (x) => x.id === data.details.driver_id
        //     ) &&
        //     data.newReason
        //   ) {
        //     color = '#FFA000'
        //     msg = `O motorista <b>${
        //       this.allFilterDrivers.find((x) => x.id === data.details.driver_id)
        //         .name
        //     } ${
        //       this.allFilterDrivers.find((x) => x.id === data.details.driver_id)
        //         .last_name
        //     }</b> adicionou uma nova ocorrência no serviço <b>#${
        //       data.details.id
        //     }</b>`
        //   } else {
        //     msg = this.allFilterDrivers.find(
        //       (x) => x.id === data.details.driver_id
        //     )
        //       ? `O motorista <b>${
        //           this.allFilterDrivers.find(
        //             (x) => x.id === data.details.driver_id
        //           ).name
        //         } ${
        //           this.allFilterDrivers.find(
        //             (x) => x.id === data.details.driver_id
        //           ).last_name
        //         }</b> ${
        //           this.getMessageByStatus(data.status)
        //             ? this.getMessageByStatus(data.status)
        //             : 'visualizou'
        //         } o serviço <b>#${data.details.id}</b>`
        //       : `O motorista cancelou o serviço <b>#${data.details.id}</b>`
        //   }

        //   console.log('msg ', msg)
        //   this.$toastInfo(msg, color)
        // this.init()
        // })
      } catch (error) {
        console.log('error ', error)
        await this.$error(
          'Ocorreu um erro no carregamento das informações, ao clicar em OK a tela será recarregada!'
        )
        // await location.reload(true)
      }
    },

    selectCustomer(item) {
      console.log('item ', item)
      if (!item) return
      console.log(this.allRequesters)
      this.allFilterRequesters = []
      this.allFilterPassengers = []
      this.allRequesters.forEach((x) => {
        if (x.customers && x.customers.length) {
          x.customers.forEach((customer) => {
            if (item.find((y) => y.id === customer.id)) {
              this.allFilterRequesters.push(x)
            }
          })
        }
      })

      this.allFilterRequesters = this.allFilterRequesters.sort((a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })

      this.allPassengers.forEach((passenger) => {
        if (
          item.find((y) => y.id === passenger.customer_id) &&
          !this.allFilterPassengersTemp.find((x) => x.id === passenger.id)
        ) {
          this.allFilterPassengersTemp.push(passenger)
        }
      })

      this.allFilterPassengersTemp = this.allFilterPassengersTemp.sort(
        (a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }
      )
    },
    getMessageByStatus(status) {
      const statusData = {
        'Em andamento': 'iniciou',
        Cancelado: 'Cancelou',
        Finalizado: 'Finalizou',
      }

      return statusData[status]
    },
    playSound() {
      const audio = new Audio(require('../assets/data/beep.mp3'))
      audio.play()
    },
    async changeStatusServices(status) {
      try {
        if (this.selectedServices.length === 0) {
          return this.$error('É necessário selecionar os serviços')
        }

        console.log('selected services ', this.selectedServices)

        const promise = this.selectedServices.map(async (x) => {
          const data = {
            id: x.id,
            status: status,
          }

          if (data.status === 'Confirmado') {
            data.confirmation_date = this.$moment().format('YYYY-MM-DD')
          }

          await service.updateService({
            ...data,
          })
        })

        await Promise.all(promise)

        this.init()
        this.isStatusChangeModalActive = false
      } catch (error) {
        alert(error)
      }
    },
    normalizePassengersByRequester() {
      const tempCustomers = this.allFilterCustomers.map((x) => {
        return {
          ...x,
          requesters: x.requesters ? x.requesters : [],
        }
      })

      console.log(tempCustomers)

      this.allPassengers.forEach((x) => {
        const verifyCustomerId = tempCustomers.find(
          (y) => y.id === x.customer_id
        )
        if (verifyCustomerId) {
          const verifyRequesterId = verifyCustomerId.requesters.find(
            (z) => z.id === this.model.requester_id
          )
          if (verifyRequesterId) {
            this.allFilterPassengers.push(x)
          }
        }
      })
    },
    onViewOccurrences(item) {
      this.viewOccurrences = item.service_occurrences
    },
    onViewObservations(item) {
      this.viewObservations = item.service_observations
    },
    getVehicleById(id) {
      return this.allVehicles.find((x) => x.id === id)
    },
    getPassengerByID(id) {
      return this.passengerList.data.find((x) => x.id === id)
    },
    async confirmService(id) {
      try {
        await service.updateService({
          id,
          status: 'Confirmado',
          confirmation_date: this.$moment().format('YYYY-MM-DD'),
        })
        this.init()
      } catch (error) {
        alert(error)
      }
    },
    async sendToDriver(id) {
      try {
        await service.updateService({
          id,
          status: 'Pendente',
        })
        this.init()
      } catch (error) {
        alert(error)
      }
    },
    filterByStatus(value) {
      if (value === 'all') {
        this.searchQuery = null
      } else {
        this.searchQuery = value
      }
    },
    getTypeByStatus(status) {
      if (status === 'Não confirmado') return 'is-primary'
      if (status === 'Confirmado') return 'is-info is-light'
      if (status === 'Pendente') return 'is-warning'
      if (status === 'Aceito') return 'is-dark'
      if (status === 'Cancelado') return 'is-danger'
      if (status === 'Em andamento') return 'is-warning is-light'
      if (status === 'Finalizado') return 'is-success'

      return 'is-dark'
    },
    resetModel() {
      this.model = {}
      this.newServiceModalActive = false
      this.isDriverCardModalActive = false
      this.isCloneServiceModalActive = false
      this.isPassengerModalActive = false
      this.isStatusChangeModalActive = false
    },
    getCustomerById(id) {
      return this.allFilterCustomers.find((x) => x.id === id)
    },
    openLogisticPage() {
      const route = this.$router.resolve({ path: '/logistics' })
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank')
      // this.$router.push('/logistics')
    },
    selectPendingRelease(index) {
      const pendingRelease = this.allFilterPendingReleases.find(
        (x) => x.id === this.passengers[index].pending_release_id
      )

      this.passengers[index].boarding_time = pendingRelease.boarding_time

      if (this.model.type === 'Ida') {
        this.passengers[index].origin_address = pendingRelease.address
        this.passengers[index].destination_address = null
      } else {
        this.passengers[index].origin_address = null
        this.passengers[index].destination_address = pendingRelease.address
      }
    },
    async savePassengers() {
      const modelTemp = { ...this.model }

      console.log('save passengers model ', modelTemp)

      let passengers = this.serviceModel.passengers.filter((x) => x.passenger)

      if (!passengers.length) return

      passengers = passengers.map((x) => {
        let boarding_time = x.boarding_time

        if (modelTemp.type === 'Volta' && !boarding_time) {
          boarding_time = modelTemp.service_time
        }

        const tempValue =
          x.totalValue && x.totalValue.toString().indexOf('R$') > -1
            ? x.totalValue.replace('R$ ', '')
            : x.totalValue

        console.log('temp value ', tempValue, x.stopTimeValue)

        x.totalValue = tempValue

        return {
          ...x,
          boarding_time,
          additional_value: x.additional_value
            ? x.additional_value.replace('R$ ', '')
            : 0.0,
          stop_time_value: x.stopTimeValue,
          holiday_value: x.holiday_value,
          interval_value: x.interval_value,
          toll_value: x.toll_value ? x.toll_value.replace('R$ ', '') : 0.0,
          value: x.value,
          total_value: x.totalValue,
          stop_time: x.stop_time,
        }
      })

      if (passengers.find((x) => !x.boarding_time)) {
        return this.$error(
          'Para salvar é necessário informar o horário de embarque de todos os passageiros.'
        )
      }

      try {
        await this.save()

        await service.saveServicePassengers(modelTemp.id, {
          passengers: passengers,
        })

        this.isPassengerModalActive = false

        this.model = Service.model
      } catch (error) {
        console.log(error)
        this.$error(error)
      }
    },
    async saveOccurrence() {
      const modelTemp = { ...this.modelOccurrence, service_id: this.model.id }

      try {
        const save = await serviceOccurrence.saveServiceOccurrence(modelTemp)

        await this.$success('Ocorrência')
        this.init()
        this.isOccurrenceCardModalActive = false
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async saveObservation() {
      const modelTemp = { ...this.modelObservation, service_id: this.model.id }

      try {
        const save = await serviceObservation.saveServiceObservation(modelTemp)

        await this.$success('Observação')
        this.init()
        this.isObservationCardModalActive = false
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },

    // normalizeDateOnSave(date) {
    //   return moment.utc(date).subtract(1, 'day').format('YYYY-MM-DD');
    // },

    async save() {
      const modelTemp = { ...this.model }

      if (
        modelTemp.id &&
        this.serviceList.data.find((x) => x.id === modelTemp.id).type !==
        modelTemp.type
      ) {
        if (modelTemp.itinerary) {
          modelTemp.itinerary = `${modelTemp.itinerary.split(' X ')[1]} X ${modelTemp.itinerary.split(' X ')[0]
            }`
        }
      }

      // if (modelTemp.id) {
      //   this.passengers.forEach((x, index) => {
      //     this.selectPassenger(index)
      //   })
      //   modelTemp.itinerary = this.model.itinerary
      // }

      // modelTemp.customers = modelTemp.customers.map((x) => x.id)

      delete modelTemp.recurrence
      delete modelTemp.taxes
      delete modelTemp.tempDate
      delete modelTemp.nameTemp
      delete modelTemp.customer

      if (modelTemp.value) {
        modelTemp.value = modelTemp.value.toString().replace('R$ ', '')
        modelTemp.value = modelTemp.value.toString().replace(',', '')
      }

      if (modelTemp.manual_value) {
        modelTemp.manual_value = modelTemp.manual_value.toString().replace('R$ ', '')
        modelTemp.manual_value = modelTemp.manual_value.toString().replace(',', '')
      }

      const serviceSave = !this.model.recurrence
        ? { ...modelTemp }
        : this.serviceDates.map((x) => {
          return {
            ...modelTemp,
            service_time: x.service_time,
            service_date: this.$moment(x.service_date).toISOString(),
          }
        })

      if (!this.model.recurrence) {
        serviceSave.service_date = this.$moment(
          serviceSave.service_date
        ).toISOString()
        if (serviceSave.confirmation_date) {
          serviceSave.confirmation_date = this.$moment(
            serviceSave.confirmation_date
          ).toISOString()
        }
      }

      console.log(serviceSave)

      if (serviceSave.id) {
        console.log('service save ', serviceSave)

        // serviceSave.service_date = this.normalizeDateOnSave(serviceSave.service_date)
      }

      try {
        const save = serviceSave.id
          ? await service.updateService(serviceSave)
          : await service.saveService(
            !this.model.recurrence
              ? { service: [serviceSave] }
              : { service: serviceSave }
          )

        await this.$success('Serviço')

        this.newServiceModalActive = false

        this.listServices()

        this.model = Service.model
      } catch (error) {
        this.$error(error)
      }
    },

    async cloneServices() {
      const services = this.selectedServices.map((x) => {
        delete x.boarding_line
        delete x.driver
        delete x.itinerary_destination
        delete x.itinerary_origin
        delete x.vehicle
        delete x.finish_date
        delete x.driver_observations
        delete x.driver_confirmation_date
        delete x.driver_cancel_date
        delete x.requester
        delete x.service_occurrences
        delete x.nameTemp
        delete x.service_observations
        delete x.taxes
        delete x.tempDate
        delete x.id

        x.customers = x.customers.map((x) => x.id)
        x.passengers = []

        return x
      })

      console.log(services)

      try {
        await service.saveService({ service: services })

        await this.$success('Serviço')
        this.init()

        this.isCloneServiceModalActive = false
      } catch (error) {
        this.$error(
          error.response.data.message || error.response.data[0].message
        )
      }
    },
    async onCancel(id) {
      try {
        const result = await this.$ask('Você deseja cancelar o serviço?')

        if (result.isConfirmed) {
          await service.updateService({
            id,
            status: 'Cancelado',
          })

          await this.$cancel('Serviço')
          this.init()
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    async onFinish(id) {
      try {
        const result = await this.$ask('Você deseja finalizar o serviço?')

        if (result.isConfirmed) {
          await service.updateService({
            id,
            status: 'Finalizado',
          })

          await this.$success('Serviço')
          this.init()
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
    copyToClipboard(text) {
      const input = document.createElement('textarea')
      input.innerHTML = text
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
    },
    onViewPassengers(item) {
      console.log('item passengers  ', item.passengers)
      this.viewPassengers = item.passengers.map((x) => {
        return {
          ...x,
          ...x.pivot,
          origin_address: x.pivot.origin_address
            ? x.pivot.origin_address
            : item.address,
          destination_address: x.pivot.destination_address
            ? x.pivot.destination_address
            : item.address,
        }
      })

      console.log('view passengers ', this.viewPassengers)
    },
    onEdit(item) {
      this.isLoading = true
      this.model = { ...item }

      delete this.model.boarding_line
      delete this.model.driver
      delete this.model.itinerary_destination
      delete this.model.itinerary_origin
      delete this.model.vehicle
      delete this.model.finish_date
      delete this.model.driver_observations
      delete this.model.driver_confirmation_date
      delete this.model.driver_cancel_date
      delete this.model.driver_cancel_date
      delete this.model.driver_cancel_date
      delete this.model.requester
      delete this.model.service_occurrences
      delete this.model.nameTemp
      delete this.model.service_observations
      delete this.model.taxes
      delete this.model.tempDate

      this.model.value = parseFloat(this.model.value)

      this.model.shared = this.model.shared ? true : false

      this.model.holiday = this.model.holiday ? true : false

      this.model.service_date = new Date(this.model.service_date)

      if (this.model.confirmation_date) {
        this.model.confirmation_date = new Date(this.model.confirmation_date)
      } else {
        delete this.model.confirmation_date
      }

      this.serviceModel.passengers = this.model.passengers.map((x) => {
        const item = {
          toll_value: x.pivot.toll_value,
          additional_value: parseFloat(x.pivot.additional_value).toFixed(2),
          value: x.pivot.value,
          cost_center: x.pivot.cost_center,
          boarding_time: x.pivot.boarding_time,
          origin_address: x.pivot.origin_address,
          destination_address: x.pivot.destination_address,
          passenger: { ...x },
          location_id: x.pivot.location_id,
        }

        delete item.passenger.pivot

        return item
      })

      delete this.model.passengers

      // this.normalizePassengers()
      this.selectCustomer(this.model.customers)

      this.isLoading = false
    },
    verifyPassenger(item) {
      if (!item.customer_id || !item.phone_number) return false

      return true
    },
    normalizePassengers() {
      this.allItinerariesFilter = this.allItineraries.filter(
        (x) => x.type === this.model.type
      )

      if (this.passengers.length > 0) {
        this.passengers.forEach((x) => {
          const passenger = this.allPassengers.find((y) => y.id === x.id)

          x.value = parseFloat(x.value).toFixed(2)
          x.additional_value = parseFloat(x.additional_value).toFixed(2)
          x.toll_value = parseFloat(x.toll_value).toFixed(2)

          if (this.model.type === 'Ida') {
            x.origin_address = passenger.address
            x.destination_address = null
          } else {
            x.origin_address = null
            x.destination_address = passenger.address
          }
        })
      }
    },
    addPassenger() {
      this.serviceModel.passengers.push({})
    },
    async changeDriver() {
      try {
        if (!this.model.driver_id || !this.model.id)
          return this.$error('Nenhum motorista selecionado!')
        const result = await this.$ask(
          'Você deseja alterar o motorista do serviço?'
        )

        if (result.isConfirmed) {
          await service.updateService({
            id: this.model.id,
            driver_id: this.model.driver_id,
          })

          await this.$success('Motorista')
          this.init()
          this.isDriverCardModalActive = false
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
      console.log('changed driver', this.model.driver_id)
    },
    removePassenger(index) {
      this.serviceModel.passengers.splice(index, 1)

      const listPassengerCity = this.serviceModel.passengers
        .filter((x) => x.passenger && x.passenger.city)
        .map((x) => x.passenger.city.toUpperCase())

      const customers = this.serviceModel.passengers
        .filter((x) => x.passenger && x.passenger.customer)
        .map((x) => x.passenger.customer)

      if (listPassengerCity.filter((x) => !x).length > 0) return

      let listCustomerAddress = ''
      let listPassengerAddress = ''

      listPassengerCity.forEach((x, index) => {
        if (listPassengerAddress.indexOf(x) == -1) {
          listPassengerAddress += `${x}`
        }

        if (
          index !== listPassengerCity.length - 1 &&
          listPassengerAddress.indexOf(listPassengerCity[index + 1]) == -1
        ) {
          listPassengerAddress += `, `
        }
      })

      customers.forEach((customer, index) => {
        if (listCustomerAddress.indexOf(customer.name) == -1) {
          listCustomerAddress += `${customer.name}`
        }

        if (
          index !== customers.length - 1 &&
          listCustomerAddress.indexOf(customers[index + 1].name) == -1
        ) {
          listCustomerAddress += `, `
        }
      })

      let itinerary = ''

      itinerary =
        this.model.type === 'Ida'
          ? `${listPassengerAddress} X ${listCustomerAddress}`
          : `${listCustomerAddress} X ${listPassengerAddress}`

      this.model.itinerary = itinerary

      console.log('passengers new ', this.serviceModel.passengers)
    },
    addServiceDate() {
      this.serviceDates.push({
        service_date: null,
        service_time: null,
      })
    },
    removeServiceDate(index) {
      this.serviceDates.splice(index, 1)
    },
    async removeSharedPassenger(id) {
      try {
        const result = await this.$ask(
          'Você deseja remover o passageiro da lista de compartilhamento?'
        )

        if (result.isConfirmed) {
          await serviceSharedServicePassenger.deleteSharedServicePassenger(id)

          await this.$delete('Passageiro')
          this.init()
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },

    //New Structure

    async listServices(query = '') {
      const queryTmp = localStorage.getItem('filter-services')

      if (queryTmp) query = queryTmp

      this.serviceList.loading = true

      const resultServices = await service.findAll({
        page: this.serviceList.currentPage,
        limit: 10,
        filter: query,
        date: this.serviceList.selectedDate
          ? moment(this.serviceList.selectedDate).format('YYYY-MM-DD ')
          : null,
        time: this.serviceList.selectedTime,
        status: this.serviceList.showAllServices
          ? []
          : [
            ServiceStatus.PENDING,
            ServiceStatus.CONFIRMED,
            ServiceStatus.IN_PROGRESS,
          ],
      })

      const serviceData = resultServices.data

      this.serviceList.data = serviceData.data.map((x) => {
        return {
          ...x,
          nameTemp: `#${x.id} - ${x.service_date
            ? this.$moment(x.service_date).format('DD/MM') +
            ' ' +
            x.service_time
            : 'N/A'
            } - ${x.status} (${x.itinerary_id
              ? x.itinerary_origin.origin.name +
              ' X ' +
              x.itinerary_destination.destination.name
              : 'N/A'
            })`,
        }
      })
      this.serviceList.total = serviceData.total

      this.serviceList.loading = false
    },
    async listRequesters() {
      const resultRequesters = await requester.getAllRequesters()

      const requesterData = resultRequesters.data

      console.log('requesterList ', requesterData)

      this.requesterList.data = requesterData
    },
    async listDrivers() {
      const resultDrivers = await driver.getAllDrivers()

      const driversData = resultDrivers.data

      this.driverList.data = driversData
    },
    async listLocations() {
      const resultLocations = await location.getAllLocations()

      const locationsData = resultLocations.data

      this.locationList.data = locationsData
    },
    async listPassengers(name, limit = 20) {
      this.passengerList.loading = true

      const resultPassengers = await passenger.findAll({
        name: name,
        limit,
      })

      const passengerData = resultPassengers.data

      this.passengerList.data = passengerData.data

      this.passengerList.loading = false
    },

    findPassenger: debounce(function (name) {
      if (!name.length) {
        this.passengerList.data = []
        return
      }
      this.listPassengers(name)
    }, 500),

    searchServices: debounce(function (query) {
      localStorage.setItem('filter-services', query)
      this.listServices(query)
    }, 500),

    selectPassenger(passenger, index, deletePassenger = false) {
      if (!passenger) return

      const findPassenger = this.serviceModel.passengers.findIndex(
        (x) => x.id === passenger.id
      )

      if (findPassenger !== -1 && !deletePassenger)
        return this.$error('Passageiro já selecionado!')

      this.serviceModel.passengers[index].passenger = passenger

      const listPassengerCity = []

      for (const item of this.serviceModel.passengers) {

        console.log('listPassengerCity item', item)

        if (!item.passenger) continue;

        let location = this.serviceModel.passengers[index].location_id;

        if (location) {
          location = this.locationList.data.find((x) => x.id === location);
        }

        const city = location ? location.name : item.passenger.city;

        listPassengerCity.push(city.toUpperCase())
      }

      const customers = this.serviceModel.passengers
        .filter((x) => x.passenger && x.passenger.customer)
        .map((x) => x.passenger.customer)

      if (listPassengerCity.filter((x) => !x).length > 0) return

      let listCustomerAddress = ''
      let listPassengerAddress = ''

      listPassengerCity.forEach((x, index) => {
        if (listPassengerAddress.indexOf(x) == -1) {
          listPassengerAddress += `${x}`
        }

        if (
          index !== listPassengerCity.length - 1 &&
          listPassengerAddress.indexOf(listPassengerCity[index + 1]) == -1
        ) {
          listPassengerAddress += `, `
        }
      })

      customers.forEach((customer, index) => {
        if (listCustomerAddress.indexOf(customer.name) == -1) {
          listCustomerAddress += `${customer.name}`
        }

        if (
          index !== customers.length - 1 &&
          listCustomerAddress.indexOf(customers[index + 1].name) == -1
        ) {
          listCustomerAddress += `, `
        }
      })

      let itinerary = ''

      const item = this.serviceModel.passengers[index]

      const passengerAddress = `${passenger.address}, ${passenger.number || 'N/A'
        } - ${passenger.district || 'N/A'}, ${passenger.city || 'N/A'} - ${passenger.state || 'N/A'
        }`

      const customerAddress = `${passenger.customer.address}`

      if (this.model.type === 'Ida') {
        itinerary = `${listPassengerAddress} X ${listCustomerAddress}`

        item.origin_address = passengerAddress
        item.destination_address = customerAddress
      } else {
        itinerary = `${listCustomerAddress} X ${listPassengerAddress}`

        item.origin_address = customerAddress
        item.destination_address = passengerAddress
      }

      this.model.itinerary = itinerary

      this.serviceModel.passengers[index] = item
      this.serviceModel.passengers[index].passenger = passenger

      console.log('serviceModel passenger ', this.serviceModel.passengers[index])

      this.calculateServiceValue()
    },

    findListPassengers() {
      const passengers = this.serviceModel.passengers.filter((x) => x.passenger)
      // this.calculateServiceValue()
      // for (const passenger of passengers) {
      //   this.calculateTotalValue(passenger)
      // }

      return passengers
    },

    normalizeServiceValueByPassengers() {
      const passangers = this.serviceModel.passengers.map((x) => {
        console.log('passenger value', x)
        const passenger = x.passenger

        const location = this.locationList.data.find(
          (y) => y.name.toUpperCase() === passenger.city.toUpperCase()
        )

        const locationCustomer = location.customers.find(
          (y) => y.id === passenger.customer.id
        )

        x.value = parseFloat(
          locationCustomer.pivot.value / this.serviceModel.passengers.length
        ).toFixed(2)

        return {
          ...x,
        }
      })

      this.serviceModel.passengers = passangers
    },

    calculateTotalValue(item) {
      console.log('item ', item)

      const additionalValue =
        item.additional_value != null
          ? parseFloat(item.additional_value.replace('R$', ''))
          : 0.0
      // const tollValue = parseFloat(
      //   item.toll_value !== null
      //     ? item.toll_value.toString().indexOf('R$') > 0
      //       ? item.toll_value.replace('R$', '')
      //       : item.toll_value
      //     : 0.0
      // )
      const tempValue =
        item.tempValue != null
          ? parseFloat(item.tempValue.replace('R$', ''))
          : 0.0
      const value = parseFloat(item.value)

      let totalValue = tempValue ? tempValue : value

      if (additionalValue) totalValue += additionalValue
      // if (tollValue) totalValue += tollValue

      item.totalValue = totalValue ? totalValue.toFixed(2) : 0.0
    },

    calculateServiceValue() {
      let cities = []
      const locationValue = this.serviceModel.passengers.map((x) => {
        let value = 0
        if (x.location_id || x.passenger.city) {
          let location = this.locationList.data.find(
            (y) =>
              y.name.toString().toUpperCase() ===
              x.passenger.city.toString().toUpperCase()
          )

          if (x.location_id) {
            location = this.locationList.data.find((y) => y.id === x.location_id);
          }

          if (location) {
            const locationCustomer = location.customers.find(
              (y) => y.id === x.passenger.customer.id
            )

            console.log('locationCustomer', locationCustomer)

            value = locationCustomer.pivot.value

            if (
              cities.indexOf((element) => element.name == location.name) == -1
            ) {
              cities.push({ name: location.name, value })
            }
          }

        }

        return value
      })

      cities = cities.filter(
        (v, i, a) =>
          a.findIndex(
            (v2) => v2.name.toUpperCase() === v.name.toUpperCase()
          ) === i
      )

      const maxLocationValue = Math.max.apply(null, locationValue)

      const selectedLocationIndex = cities.findIndex(
        (x) => x.value === maxLocationValue
      )

      if (selectedLocationIndex !== -1) cities.splice(selectedLocationIndex, 1)
      console.log('citites length 1 ', cities)

      if (cities.length > 0) cities.splice(0, 1)

      console.log('citites length 2 ', cities)

      const passengers = this.serviceModel.passengers.map((x) => {
        x.stopTimeValue = x.passenger.customer.stop_time * (x.stop_time || 0)

        x.extra_local_value =
          (x.passenger.customer.extra_local_value || 0) * cities.length

        x.totalValue = parseFloat(maxLocationValue) + x.stopTimeValue

        let serviceTime = this.model.service_time.toString().split(':')[0]
        serviceTime = parseInt(serviceTime)

        x.holidayValue = x.holidayValue || 0

        x.totalValue = x.totalValue + x.extra_local_value

        console.log(
          'holiday ',
          x.holidayValue,
          ' total ',
          x.totalValue,
          ' extra local ',
          x.extra_local_value
        )

        let addValueIntervaltime = 0

        if (!this.model.holiday) {
          for (const element of x.passenger.customer.time_intervals) {
            let initTime = element.init_time.toString().split(':')[0]
            initTime = parseInt(initTime)

            let endTime = element.end_time.toString().split(':')[0]
            endTime = parseInt(endTime)

            const twoDays = initTime > endTime

            if (twoDays) {
              if (
                (serviceTime >= initTime && serviceTime <= 23) ||
                (serviceTime <= endTime && serviceTime >= 1)
              ) {
                addValueIntervaltime =
                  x.totalValue * (element.pivot.value / 100)
              }
            } else if (serviceTime >= initTime && serviceTime <= endTime) {
              addValueIntervaltime = x.totalValue * (element.pivot.value / 100)
            }
          }
        } else {
          const holidayValue = x.passenger.customer.holiday_value || 0

          if (holidayValue > 0) {
            const valueTmp = x.totalValue * (holidayValue / 100)
            x.holiday_value = valueTmp
          }
        }

        console.log('addValueIntervaltime  ', addValueIntervaltime)

        x.interval_value = addValueIntervaltime || 0
        x.holiday_value = x.holiday_value || 0

        console.log(
          'totalValue ',
          x.totalValue,
          ' addValueIntervaltime ',
          addValueIntervaltime,
          ' time intervals ',
          x.passenger.customer.time_intervals
        )

        x.totalValue = x.totalValue + addValueIntervaltime + x.holiday_value

        x.totalValueTmp = parseFloat(
          x.totalValue / this.serviceModel.passengers.length
        ).toFixed(2)
        x.value = x.totalValueTmp
        x.totalValue = parseFloat(x.totalValue).toFixed(2)

        return {
          ...x,
        }
      })

      this.serviceModel.passengers = passengers
    },
  },
}
</script>

<style scoped>
.fullscreen-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
